import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializeFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAuZLqeKLZ5OU0nW8kcKFlMX2eg6f_pEUA",
  authDomain: "oranjemund-town-council-38942.firebaseapp.com",
  projectId: "oranjemund-town-council-38942",
  storageBucket: "oranjemund-town-council-38942.appspot.com",
  messagingSenderId: "867625115879",
  appId: "1:867625115879:web:37beb38f9edc75a3c30242"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const appAuthWorker = initializeApp(firebaseConfig, "authWorker");

export const auth = getAuth(app);
export const authWorker = getAuth(appAuthWorker);

export const analytics = getAnalytics(app);
export const db = initializeFirestore(app, {
  experimentalForceLongPolling: true,
});

export const storage = getStorage(app);
export const functions = getFunctions(app);

// ORANJEMUND
// apiKey: "AIzaSyDkcY-qIHupPiOmVmHQN-cPAGGHvEtCfXw",
// authDomain: "oranjemund-town-council.firebaseapp.com",
// projectId: "oranjemund-town-council",
// storageBucket: "oranjemund-town-council.appspot.com",
// messagingSenderId: "536594440744",
// appId: "1:536594440744:web:af73cb58bc56858e9ccb1d",
// measurementId: "G-9PTRC0NFT0"



// OMUTHIYA
// apiKey: "AIzaSyB5dWTgpPEjWo3yBpvyzPqcE4w0Aa7g55A",
// authDomain: "omuthiya-town-council.firebaseapp.com",
// projectId: "omuthiya-town-council",
// storageBucket: "omuthiya-town-council.appspot.com",
// messagingSenderId: "174413294640",
// appId: "1:174413294640:web:b6b6944ecf5e916a75d1f5",
// measurementId: "G-X08DN1CZ48",

// OKAHANDJA
// apiKey: "AIzaSyAaM-9Yp6FCNTQWgoRy2OboPa8a3podPvs",
// authDomain: "okahandja-municipality-pms.firebaseapp.com",
// projectId: "okahandja-municipality-pms",
// storageBucket: "okahandja-municipality-pms.appspot.com",
// messagingSenderId: "35628990074",
// appId: "1:35628990074:web:9933282ffe008a846085c3",
// measurementId: "G-H55XYY4029"

// NKURENKURU
// apiKey: "AIzaSyDasgXX3u3cLujb7rwGlJpqgXV2wwfmKS8",
// authDomain: "nkurenkuru-town-council.firebaseapp.com",
// projectId: "nkurenkuru-town-council",
// storageBucket: "nkurenkuru-town-council.appspot.com",
// messagingSenderId: "651659197779",
// appId: "1:651659197779:web:122730299f6c30bbacf5a6",
// measurementId: "G-N3BJ50MDSN"

// UNICOMMS
// apiKey: "AIzaSyBnqOTasd03TEEeNx-ZPx2cS6QSBr07Ey4",
// authDomain: "unicommspms.firebaseapp.com",
// projectId: "unicommspms",
// storageBucket: "unicommspms.appspot.com",
// messagingSenderId: "1039071543676",
// appId: "1:1039071543676:web:fb4201dc72804005e7e4f9",
// measurementId: "G-0GV26556D0",

//pms-work shop
// apiKey: "AIzaSyBoo1MsZAa7C6N7g4F_kd20_9F47EWsnxU",
// authDomain: "pms-workshop.firebaseapp.com",
// projectId: "pms-workshop",
// storageBucket: "pms-workshop.appspot.com",
// messagingSenderId: "1003334609013",
// appId: "1:1003334609013:web:fe72f5904e63c2acf4f03c",
// measurementId: "G-HCEE2B52PK",

//group 2
// apiKey: "AIzaSyA_ujThnnpUKdLUiY_-WOIhIj3jKdrWG08",
// authDomain: "pms-group-2.firebaseapp.com",
// projectId: "pms-group-2",
// storageBucket: "pms-group-2.appspot.com",
// messagingSenderId: "402723934481",
// appId: "1:402723934481:web:af871ec1cc5cdf5e3dbcb0",
// measurementId: "G-ELS4J5LCCT"

//group 3
// apiKey: "AIzaSyCMrjZqgOeLET3NMtAjxv7hjF6wJMOzbLc",
// authDomain: "pms-group-3.firebaseapp.com",
// projectId: "pms-group-3",
// storageBucket: "pms-group-3.appspot.com",
// messagingSenderId: "978119556501",
// appId: "1:978119556501:web:bddcaaa45e2dc63c434a9c",
// measurementId: "G-JRHHRE9NM0"

//group 4
// apiKey: "AIzaSyAInwMfNfR5TJLBQ38BYSajG7QXc1hm_RY",
// authDomain: "pms-group-4.firebaseapp.com",
// projectId: "pms-group-4",
// storageBucket: "pms-group-4.appspot.com",
// messagingSenderId: "267726886996",
// appId: "1:267726886996:web:5eb7e9690c42197de0b5c1",
// measurementId: "G-Q91NGZ3P9T"
