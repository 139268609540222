// eslint-disable-next-line array-callback-return
// eslint-disable-next-line no-restricted-globals
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useAppContext } from "../../shared/functions/Context";
import ErrorBoundary from "../../shared/components/error-boundary/ErrorBoundary";
import useTitle from "../../shared/hooks/useTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faPlus } from "@fortawesome/free-solid-svg-icons";
import MODAL_NAMES from "../dialogs/ModalName";
import Modal from "../../shared/components/Modal";
import NewProjectModal from "../dialogs/project-management/NewProjectModal";
import showModalFromId from "../../shared/functions/ModalShow";
import { useNavigate } from "react-router-dom";
import ProjectTabs from "./tabs/ProjectsTabs";
import { getInitials, timeFormart } from "./utils/common";
import useBackButton from "../../shared/hooks/useBack";
import moment from "moment";
import Filter from "./utils/filter";
import {
  defaultProject,
  IProject,
  IProjectStatus,
} from "../../shared/models/ProjectManagement";
import { USER_ROLES } from "../../shared/functions/CONSTANTS";
import SingleSelect from "../../shared/components/single-select/SingleSelect";
import NumberInput from "../shared/components/number-input/NumberInput";
import Loading from "../../shared/components/loading/Loading";
import "./styles/projects.style.scss";

const UserProjects = observer(() => {
  const { api, store, ui } = useAppContext();
  const navigate = useNavigate();

  const me = store.auth.meJson;
  const role = store.auth.role;

  useTitle("Projects Home");
  useBackButton("/c/portfolio");

  // const scorecard = store.scorecard.active;
  const [selectedTab, setselectedTab] = useState("all");
  const [selectedValue, setSelectedValue] = useState("all");

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const [selectedProject, setSelectedProject] = useState<IProject>({
    ...defaultProject,
  });

  const projects: IProject[] = store.projectManagement.all.map((p) => p.asJson);
  const departments = store.department.all.map((d) => ({
    id: d.asJson.id,
    name: d.asJson.name,
  }));

  const portfolioOptions = store.portfolio.all.map((portfolio) => ({
    label: portfolio.asJson.portfolioName,
    value: portfolio.asJson.id,
  }));

  const handleNewProject = () => {
    store.projectManagement.clearSelected();
    showModalFromId(MODAL_NAMES.PROJECTS.CREATE_PROJECT);
  };

  const viewProject = (project: IProject) => {
    store.projectManagement.select(project);
    navigate(`/c/project/${project.id}`);
  };

  const quickProjectUpdate = (_project: IProject) => {
    store.projectManagement.select(_project);
    if (store.projectManagement.selected)
      setSelectedProject(store.projectManagement.selected);
    else setSelectedProject(defaultProject);
  };

  const handleUpdateProject = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!selectedProject) return;

    try {
      setLoading(true);
      await api.projectManagement.updateProject(selectedProject);
      setLoading(false);
    } catch (error) {
      ui.snackbar.load({
        id: Date.now(),
        message: "Error! Failed to update.",
        type: "danger",
      });
    }
  };

  const handleDeleteProject = async () => {
    setLoading(true);

    try {
      await api.projectManagement.getTasks(selectedProject.id);
      await api.projectManagement.getRisks(selectedProject.id);
      await api.projectManagement.getProjectLogs(selectedProject.id);

      if (
        store.projectTask.all.length !== 0 ||
        store.projectLogs.all.length !== 0 ||
        store.projectRisk.all.length !== 0
      ) {
        ui.snackbar.load({
          id: Date.now(),
          message: "This project contain data, delete the data first.",
          type: "danger",
          timeoutInMs: 5000,
        });
      } else {
        await api.projectManagement.deleteProject(selectedProject);
      }
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    setLoadingData(true);
    const loadData = async () => {
      if (!me) return;

      if (role === USER_ROLES.EXECUTIVE_USER || role === USER_ROLES.GUEST_USER)
        await api.projectManagement.getAllProjects();
      else if (role === USER_ROLES.MANAGER_USER)
        await api.projectManagement.getProjectsByDepartment(me.department);
      else await api.projectManagement.getUserProjects(me.uid);
    };
    loadData();
    setLoadingData(false);
  }, [api.projectManagement, role, me]);

  useEffect(() => {
    const loadData = async () => {
      if (store.user.all.length < 2) {
        await api.user.getAll();
      }
      if (store.department.all.length < 1) {
        await api.department.getAll();
      }
    };
    loadData();
  }, [api.user, api.department]);

  if (loadingData) return <Loading />;

  return (
    <ErrorBoundary>
      <div className="topMain">
        <ProjectTabs
          selectedTab={selectedTab}
          setselectedTab={setselectedTab}
        />
        <div className="p-navbar">
          <button className="btn btn-primary" type="button">
            <span>Filter&nbsp;&nbsp;</span>
            <FontAwesomeIcon
              icon={faFilter}
              className="icon uk-margin-small-right"
            />
          </button>
          <div uk-drop="mode: click">
            <Filter
              list={[...departments, { name: "All Departments", id: "all" }]}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
            />
          </div>
          &nbsp;&nbsp;&nbsp;
          <button className="btn btn-primary" onClick={handleNewProject}>
            <span>New Project&nbsp;&nbsp;</span>
            <FontAwesomeIcon
              icon={faPlus}
              className="icon uk-margin-small-right"
            />
          </button>
        </div>
      </div>
      <div className="bottomMain">
        {projects
          .filter((project) => {
            if (selectedTab === "all") return project;
            else if (project.status === selectedTab) return project;
          })
          .filter((project) => {
            if (selectedValue === "all") return project;
            else if (project.department === selectedValue) return project;
          })
          .map((project) => (
            <div key={project.id}>
              <div className="uk-card uk-card-hover">
                <div
                  className="card-content uk-card-body"
                  onClick={() => viewProject(project)}
                >
                  <h5
                    className="h-5"
                    data-uk-tooltip={`${project.projectName}`}
                  >
                    {project.projectName}
                  </h5>
                  <p>{project.description ? project.description : "."}</p>
                  <div className="users">
                    {me &&
                      project.usersId.slice(0, 5).map((userId) => {
                        const user =
                          store.user.getItemById(userId)?.asJson.displayName;
                        return (
                          <div
                            className="user"
                            style={{ textTransform: "uppercase" }}
                            key={userId}
                            data-uk-tooltip={user}
                          >
                            {user !== me.displayName
                              ? getInitials(`${user}`)
                              : "ME"}
                          </div>
                        );
                      })}
                    {project.usersId.length > 5 ? (
                      <div
                        className="user"
                        style={{ textTransform: "uppercase" }}
                        data-k-tooltip={project.usersId
                          .slice(5)
                          .map(
                            (userId) =>
                              store.user.getItemById(userId)?.asJson.displayName
                          )}
                      >
                        +{project.usersId.length - 5}
                      </div>
                    ) : null}
                  </div>
                  <span style={{ fontSize: ".6rem" }}>
                    {moment(project.startDate).calendar(null, timeFormart)} -{" "}
                    {moment(project.endDate).calendar(null, timeFormart)}
                  </span>
                </div>
                <div className="uk-inline card-actions">
                  <div
                    className={`card-badge ${project.status}`}
                    data-uk-tooltip="Project Status"
                  >
                    <span>{project.status}</span>&nbsp;
                  </div>

                  <button
                    className={`card-badge ${project.status}`}
                    data-uk-tooltip="Edit"
                    type="button"
                    onClick={() => quickProjectUpdate(project)}
                  >
                    <span uk-icon="icon:more; ratio: .5"></span>
                  </button>

                  <form
                    onSubmit={handleUpdateProject}
                    className="uk-card uk-card-default p-actions"
                    data-uk-drop="mode: click"
                    style={{ borderRadius: "6px", width: "500px" }}
                  >
                    <div className="drop-input">
                      <input
                        type="text"
                        className="uk-text-small"
                        name="name"
                        id="name"
                        defaultValue={selectedProject.projectName}
                        onChange={(e) =>
                          setSelectedProject({
                            ...selectedProject,
                            projectName: e.target.value,
                          })
                        }
                      />
                    </div>
                    <p className="uk-text-small">Status: {project.status}</p>
                    <div>
                      <div
                        className="uk-margin uk-grid-medium uk-child-width-auto uk-grid"
                        data-uk-grid
                      >
                        <label>
                          <input
                            className="uk-radio"
                            type="radio"
                            name="status"
                            value={"active"}
                            onChange={(e) =>
                              setSelectedProject({
                                ...selectedProject,
                                status: e.target.value as IProjectStatus,
                              })
                            }
                          />{" "}
                          Active{" "}
                        </label>
                        <label>
                          <input
                            className="uk-radio"
                            type="radio"
                            name="status"
                            value={"on-hold"}
                            onChange={(e) =>
                              setSelectedProject({
                                ...selectedProject,
                                status: e.target.value as IProjectStatus,
                              })
                            }
                          />{" "}
                          On-Hold{" "}
                        </label>
                        <label>
                          <input
                            className="uk-radio"
                            type="radio"
                            name="status"
                            value={"at-risk"}
                            onChange={(e) =>
                              setSelectedProject({
                                ...selectedProject,
                                status: e.target.value as IProjectStatus,
                              })
                            }
                          />{" "}
                          At Risk{" "}
                        </label>
                        <label>
                          <input
                            className="uk-radio"
                            type="radio"
                            name="status"
                            value={"completed"}
                            onChange={(e) =>
                              setSelectedProject({
                                ...selectedProject,
                                status: e.target.value as IProjectStatus,
                              })
                            }
                          />{" "}
                          Completed{" "}
                        </label>
                      </div>
                      <div
                        className="uk-grid-column-small uk-grid-row-large uk-child-width-1-2@s"
                        data-uk-grid
                      >
                        <div>
                          <label className="uk-text-small" htmlFor="start">
                            Start Date
                          </label>
                          <input
                            id="start"
                            className="uk-input"
                            type="date"
                            placeholder="Start Date"
                            defaultValue={selectedProject.startDate}
                            onChange={(e) =>
                              setSelectedProject({
                                ...selectedProject,
                                startDate: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div>
                          <label className="uk-text-small" htmlFor="end">
                            End Date
                          </label>
                          <input
                            id="end"
                            className="uk-input"
                            type="date"
                            placeholder="Start Date"
                            defaultValue={selectedProject.endDate}
                            onChange={(e) =>
                              setSelectedProject({
                                ...selectedProject,
                                endDate: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div>
                          <label
                            className="uk-text-small"
                            htmlFor="budgeted-amount"
                          >
                            Budgeted Amount ({selectedProject.currency})
                          </label>
                          <NumberInput
                            className="uk-input uk-form-small"
                            value={project.budgetedAmount}
                            onChange={(value) =>
                              setSelectedProject({
                                ...selectedProject,
                                budgetedAmount: Number(value),
                              })
                            }
                          />
                        </div>
                        <div>
                          <label
                            className="uk-text-small"
                            htmlFor="awarded-amount"
                          >
                            Awarded Amount ({selectedProject.currency})
                          </label>
                          <NumberInput
                            className="uk-input uk-form-small"
                            value={project.awardedAmount}
                            onChange={(value) =>
                              setSelectedProject({
                                ...selectedProject,
                                awardedAmount: Number(value),
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="uk-margin">
                        <label className="uk-text-small" htmlFor="portfolio">
                          Portfolio
                        </label>
                        <SingleSelect
                          name="portifolio"
                          options={portfolioOptions}
                          width="100%"
                          onChange={(value) =>
                            setSelectedProject({
                              ...selectedProject,
                              portfolioId: value,
                            })
                          }
                          placeholder={selectedProject.portfolioId}
                        />
                      </div>

                      {/* {me?.uid === selectedProject.manager && */}
                      <div className="uk-flex uk-flex-between">
                        <div>
                          <button
                            className="delete-project-button"
                            onClick={handleDeleteProject}
                            type="button"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="1"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-trash"
                            >
                              <polyline points="3 6 5 6 21 6"></polyline>
                              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                            </svg>
                            <span>Delete Project</span>
                          </button>
                        </div>
                        <div>
                          <button className="save-project-button" type="submit">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="1"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-save"
                            >
                              <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
                              <polyline points="17 21 17 13 7 13 7 21"></polyline>
                              <polyline points="7 3 7 8 15 8"></polyline>
                            </svg>
                            <span>Save</span>
                          </button>
                        </div>
                      </div>
                      {/* } */}
                      {loading && (
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            display: "grid",
                            placeItems: "center",
                            backgroundColor: "#00000015",
                          }}
                        >
                          <div data-uk-spinner="ratio: 2"></div>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ))}
      </div>
      <ErrorBoundary>
        <Modal modalId={MODAL_NAMES.PROJECTS.CREATE_PROJECT}>
          <NewProjectModal />
        </Modal>
      </ErrorBoundary>
    </ErrorBoundary>
  );
});

export default UserProjects;
